module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-MBKH8PH5","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Folkestone Documentary Festival","short_name":"Folkestone Documentary Festival","start_url":"/","background_color":"rgb(44,57,79)","theme_color":"rgb(44,57,79)","display":"minimal-ui","icon":"src/images/FoDo-bird.svg","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a3ac70aebec21e12b3bc47061179172e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5aTzNXWEJRQUFDZ0FPMTZo.77-977-977-9H--_ve-_ve-_ve-_ve-_ve-_ve-_vXzvv73vv71YCzDvv70t77-977-977-977-977-9LO-_ve-_ve-_vRXvv73vv713","repositoryName":"folkestonedocumentaryfest","routes":[{"type":"page","uid":"index","path":"/:lang?"},{"type":"page","path":"/:lang?/:uid"},{"type":"event","path":"/:lang?/events/:uid"},{"type":"venue","path":"/:lang?/venues/:uid"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
